






































































import { Vue, Component } from "vue-property-decorator";

import newsStore from "@/store/modules/newsFeed";
import NoticeHome from "@/store/modules/noticeHome";

@Component
export default class NoticeDetail extends Vue {
  id: string = "";
  get notice() {
    return newsStore.thenews;
  }
  get news() {
    return NoticeHome.newsList;
  }
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      newsStore.loadNewsFeedById(this.id);
    }
    console.log("bye", this.news);
  }
}
